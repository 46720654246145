import { createFileRoute } from '@tanstack/react-router';

import {
  InvoiceFormPage,
  InvoiceFormPending,
} from '@/pages/finances/invoice-form';

export const Route = createFileRoute('/_default/finances/invoice-form')({
  component: InvoiceFormPage,
  pendingComponent: InvoiceFormPending,
  onEnter: () => {
    document.title = 'Квитанция об оплате | InStudy 2.0';
  },
});
