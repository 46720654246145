import { QueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { educationQueries } from '@/api/endpoints/education';

import { EducationPage, EducationPending } from '@/pages/education/index';

const educationSearchSchema = z.object({
  semester: z.number().optional(),
});

export const Route = createFileRoute('/_default/education/')({
  component: EducationPage,
  pendingComponent: EducationPending,
  loader: async ({ context }: { context: { queryClient: QueryClient } }) =>
    context.queryClient.ensureQueryData(educationQueries.semesters()),
  onEnter: () => {
    document.title = 'Образовательный процесс | InStudy 2.0';
  },
  validateSearch: zodValidator({
    schema: educationSearchSchema,
    input: 'output',
    output: 'input',
  }),
});
