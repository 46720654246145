import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { AnnouncesPage, AnnouncesPending } from '@/pages/announces/index';

const announceSearchSchema = z.object({
  page: fallback(z.number(), 1).default(1),
  type: fallback(
    z.enum(['Все', 'Разработка', 'Важно', 'Новости', 'Разное']),
    'Все',
  ).default('Все'),
});

export const Route = createFileRoute('/_default/announces/')({
  component: AnnouncesPage,
  pendingComponent: AnnouncesPending,
  validateSearch: zodValidator({
    schema: announceSearchSchema,
    input: 'output',
    output: 'input',
  }),
  onEnter: () => {
    document.title = 'Объявления от деканата | InStudy 2.0';
  },
});
