import { createFileRoute } from '@tanstack/react-router';

import {
  ExternalResourcesPage,
  ExternalResourcesPending,
} from '@/pages/library/external-resources';

export const Route = createFileRoute('/_default/library/external-resources')({
  component: ExternalResourcesPage,
  pendingComponent: ExternalResourcesPending,
  onEnter: () => {
    document.title = 'Внешние ресурсы | InStudy 2.0';
  },
});
