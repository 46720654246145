import { createFileRoute } from '@tanstack/react-router';

import { LibraryPage, LibraryPending } from '@/pages/library/index';

export const Route = createFileRoute('/_default/library/')({
  component: LibraryPage,
  pendingComponent: LibraryPending,
  pendingMs: 1,
  onEnter: () => {
    document.title = 'Электронная библиотека | InStudy 2.0';
  },
});
