import { createFileRoute } from '@tanstack/react-router';

import { ServicePage, ServicePending } from '@/pages/service';

export const Route = createFileRoute('/_default/service')({
  component: ServicePage,
  pendingComponent: ServicePending,
  onEnter: () => {
    document.title = 'Сервисы | InStudy 2.0';
  },
});
