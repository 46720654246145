import { createFileRoute, notFound } from '@tanstack/react-router';
import { AxiosError } from 'axios';

import { scheduleQueries } from '@/api/endpoints/schedule';

import { NotFound } from '@/components/ui/not-found';

import { LessonPage, LessonPending } from '@/pages/schedule/lesson';

export const Route = createFileRoute('/_default/schedule/$lessonId')({
  component: LessonPage,
  loader: async ({ params: { lessonId }, context: { queryClient } }) => {
    return await queryClient
      .ensureQueryData(scheduleQueries.lesson(lessonId))
      .catch((error: AxiosError) => {
        if (error.status === 404) {
          throw notFound();
        }
      });
  },
  onEnter: ({ loaderData }) => {
    if (loaderData) {
      document.title = `Занятие - ${loaderData.discipline} | InStudy 2.0`;
    }
  },
  pendingComponent: LessonPending,
  notFoundComponent: () => (
    <NotFound
      subTitle='Запрашиваемый вами урок не найден'
      backLink='/schedule'
    />
  ),
});
