import { queryOptions } from '@tanstack/react-query';

import {
  EEducationDisciplineAssentType,
  TEducationDisciplineDTO,
  TEducationResponse,
  TEducationSemester,
  TEducationTestDTO,
  TEducationTestFinish,
  TEducationTestFinishResult,
  TEducationTestItemDTO,
  TEducationWorkItemDTO,
  TEducationWorkOptionsResponse,
} from './types.ts';

import { httpWrapper, TAPIErrorsResponse } from '@/api/client';

const fetchDisciplines = (semester: number, signal: AbortSignal) => {
  return httpWrapper.get<TEducationResponse>(
    `semesters/${semester}/disciplines`,
    { signal },
  );
};
const fetchSemesters = () => {
  return httpWrapper.get<TEducationSemester>('semesters');
};
const fetchDiscipline = (disciplineId: string) => {
  return httpWrapper.get<TEducationDisciplineDTO>(
    'disciplines/' + disciplineId,
  );
};
export const selectDiscipline = (semester: number, disciplineId: number) => {
  return httpWrapper.post<true | TAPIErrorsResponse>(
    `semesters/${semester}/disciplines/${disciplineId}`,
  );
};

const fetchTests = (
  disciplineId: string,
  workType: EEducationDisciplineAssentType,
) => {
  return httpWrapper.get<TEducationTestItemDTO[]>(
    `disciplines/${disciplineId}/tests?workType=${workType}`,
  );
};
export const finishTest = (
  disciplineId: string,
  testId: string,
  data: TEducationTestFinish,
) => {
  return httpWrapper.post<TEducationTestFinishResult>(
    `disciplines/${disciplineId}/tests/${testId}`,
    data,
  );
};
const fetchWorks = (disciplineId: string) => {
  return httpWrapper.get<TEducationWorkItemDTO[]>(
    `disciplines/${disciplineId}/works`,
  );
};
const fetchWorkOptions = (disciplineId: string) => {
  return httpWrapper.get<TEducationWorkOptionsResponse>(
    `disciplines/${disciplineId}/options/work`,
  );
};
export const uploadWork = (
  disciplineId: string,
  workId: number,
  formData: FormData,
) => {
  return httpWrapper.post<TEducationWorkItemDTO>(
    `disciplines/${disciplineId}/works/${workId}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};
export const downloadWork = (name: string) => {
  return httpWrapper.post<string>(
    `download/work`,
    {
      name,
    },
    {
      responseType: 'blob',
    },
  );
};

const fetchTest = (disciplineId: string, testId: string) => {
  return httpWrapper.get<TEducationTestDTO>(
    `disciplines/${disciplineId}/tests/${testId}`,
  );
};

export const educationKeys = {
  disciplines: (semester: number | string | undefined) => [
    'education',
    'semesters',
    semester,
    'disciplines',
  ],
  disciplineWorks: (disciplineId: string) => [
    'education',
    'disciplines',
    disciplineId,
    'works',
  ],
  disciplineTests: (
    disciplineId: string,
    workType: EEducationDisciplineAssentType,
  ) => ['education', 'disciplines', disciplineId, 'tests', workType],
};
export const educationQueries = {
  disciplines: (semester: number | string | undefined) =>
    queryOptions({
      queryKey: educationKeys.disciplines(semester),
      queryFn: ({ signal }) => fetchDisciplines(semester as number, signal),
      enabled: typeof semester === 'number',
    }),
  semesters: () =>
    queryOptions({
      queryKey: ['education', 'semesters'],
      queryFn: fetchSemesters,
    }),
  discipline: (disciplineId: string) =>
    queryOptions({
      queryKey: ['education', 'disciplines', disciplineId],
      queryFn: () => fetchDiscipline(disciplineId),
    }),
  disciplineTests: (
    disciplineId: string,
    workType: EEducationDisciplineAssentType,
  ) =>
    queryOptions({
      queryKey: educationKeys.disciplineTests(disciplineId, workType),
      queryFn: () => fetchTests(disciplineId, workType),
    }),
  disciplineWorks: (disciplineId: string) =>
    queryOptions({
      queryKey: educationKeys.disciplineWorks(disciplineId),
      queryFn: () => fetchWorks(disciplineId),
    }),
  workOptions: (disciplineId: string) =>
    queryOptions({
      queryKey: ['education', 'disciplines', disciplineId, 'workOptions'],
      queryFn: () => fetchWorkOptions(disciplineId),
    }),
  test: (disciplineId: string, testId: string) =>
    queryOptions({
      queryKey: ['education', 'disciplines', disciplineId, 'tests', testId],
      queryFn: () => fetchTest(disciplineId, testId),
    }),
};
