import { createFileRoute } from '@tanstack/react-router';

import {
  PortfolioUploadPage,
  PortfolioUploadPending,
} from '@/pages/portfolios/portfolio/upload';

export const Route = createFileRoute(
  '/_default/portfolio/$portfolioId_/upload',
)({
  component: PortfolioUploadPage,
  pendingComponent: PortfolioUploadPending,
  onEnter: () => {
    document.title = 'Загрузка файла в портфолио | InStudy 2.0';
  },
});
