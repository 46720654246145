import { queryOptions } from '@tanstack/react-query';

import { httpWrapper } from '@/api/client';

export type TScheduleLessonExam = 0 | 1 | 5;

export type TScheduleLessonItemDTO = Pick<
  TScheduleLessonDTO,
  | 'id'
  | 'discipline'
  | 'online'
  | 'route'
  | 'period'
  | 'exam'
  | 'cancel'
  | 'isPassed'
  | 'autoRegLink'
> & {
  teacher: string;
};
export type TScheduleLessonDTO = {
  id: number;
  discipline: string;
  disciplineId: number;
  teacher: {
    name: string;
    rank: string;
    degree: string;
    id: number;
    photoUrl: string | null;
  };
  autoRegLink: boolean | null;
  isPassed: boolean;
  online: 1 | 0;
  route: string;
  period: string;
  date: string;
  exam: TScheduleLessonExam;
  cancel: 0 | 1;
  title?: string;
  duration?: number;
  description: string;
  mp4: string;
};
export type TScheduleDayDTO = {
  date: string;
  exam: TScheduleLessonExam[];
};
export type TScheduleSemesterDTO = {
  start: string;
  finish: string;
};
type TScheduleByDateDTO = {
  prevDays: TScheduleDayDTO[];
  nextDays: TScheduleDayDTO[];
  current: TScheduleLessonItemDTO[];
  nearest: { date: string; schedule: TScheduleLessonItemDTO[] } | null;
};
const fetchScheduleToday = () => {
  return httpWrapper.get<TScheduleLessonItemDTO[]>('widgets/schedule');
};
const fetchLesson = (lessonId: string) => {
  return httpWrapper.get<TScheduleLessonDTO>(`schedule/${lessonId}`);
};
const fetchScheduleByDate = (date: string) => {
  return httpWrapper.get<TScheduleByDateDTO>('schedule?date=' + date);
};
const fetchScheduleMonth = (month: number, year: number) => {
  return httpWrapper.get<TScheduleDayDTO[]>(
    `schedule?month=${month}&year=${year}`,
  );
};
const fetchScheduleSemester = () => {
  return httpWrapper.get<TScheduleSemesterDTO>('semester');
};
const synchronizeCalendar = () => {
  return httpWrapper.get<{ link: string }>('calendar');
};

export const createScheduleLink = (route: string) =>
  httpWrapper.post<{ url: string }>('schedule/link', { route });

export const scheduleQueries = {
  today: () =>
    queryOptions({
      queryKey: ['schedule', 'today'],
      queryFn: fetchScheduleToday,
    }),
  lesson: (lessonId: string) =>
    queryOptions({
      queryKey: ['schedule', 'lesson', lessonId],
      queryFn: () => fetchLesson(lessonId),
    }),
  byDate: (date: string) =>
    queryOptions({
      queryKey: ['schedule', 'date', date],
      queryFn: () => fetchScheduleByDate(date),
    }),
  month: (month: number, year: number) =>
    queryOptions({
      queryKey: ['schedule', 'month', { month, year }],
      queryFn: () => fetchScheduleMonth(month, year),
    }),
  semester: () =>
    queryOptions({
      queryKey: ['schedule', 'semester'],
      queryFn: fetchScheduleSemester,
      staleTime: Infinity,
    }),
  syncCalendar: () =>
    queryOptions({
      queryKey: ['schedule', 'calendar'],
      queryFn: synchronizeCalendar,
    }),
};
