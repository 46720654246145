import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { EMessageFilterType } from '@/api/endpoints/chat';

import { ChatsPage, ChatsPending } from '@/pages/chats/index';

const searchSchema = z.object({
  group: fallback(
    z.enum([
      EMessageFilterType.MY_DIALOGUES,
      EMessageFilterType.TEACHERS,
      EMessageFilterType.CLASSMATES,
    ]),
    EMessageFilterType.MY_DIALOGUES,
  ).default(EMessageFilterType.MY_DIALOGUES),
  query: z.string().optional(),
});

export const Route = createFileRoute('/_default/chats/')({
  component: ChatsPage,
  pendingComponent: ChatsPending,
  validateSearch: zodValidator({
    schema: searchSchema,
    input: 'output',
    output: 'input',
  }),
  onEnter: () => {
    document.title = 'Сообщения | InStudy 2.0';
  },
});
