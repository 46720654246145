import { createFileRoute } from '@tanstack/react-router';

import { CuratorPage, CuratorPending } from '@/pages/curator-materials';

export const Route = createFileRoute('/_default/curator')({
  component: CuratorPage,
  pendingComponent: CuratorPending,
  onEnter: () => {
    document.title = 'Ваш куратор | InStudy 2.0';
  },
});
