import { captureException } from '@sentry/react';
import { ErrorComponentProps } from '@tanstack/react-router';
import { Button, Card, Flex, Result, Typography } from 'antd';
import axios from 'axios';
import { useEffect } from 'react';

import { AxiosErrorBoundary } from './AxiosErrorBoundary.tsx';
import { useStyles } from './styles.ts';

interface IErrorBoundaryProps {
  error: ErrorComponentProps;
  on401?: () => void;
}

export const ErrorBoundary = ({ error, on401 }: IErrorBoundaryProps) => {
  const { styles } = useStyles();

  useEffect(() => {
    captureException(error);
  }, [error]);

  if (axios.isAxiosError(error.error)) {
    if (error.error.status === 401 && on401) {
      on401();
    } else {
      return <AxiosErrorBoundary error={error.error} />;
    }
  }

  return (
    <Result
      status='error'
      title='Непредвиденная ошибка'
      className={styles.result}
      extra={
        import.meta.env.VITE_IS_LOCAL_DEVELOPMENT ||
        import.meta.env.NODE_ENV === 'development' ? (
          <Flex vertical gap='large' align='center' justify='center'>
            <Card className={styles.errorCard}>
              <Flex vertical>
                <Typography.Title level={4}>
                  {error.error.name} - {error.error.message}
                </Typography.Title>
                <Typography.Text>{error.error.stack}</Typography.Text>
                <Typography.Text>{error.info?.componentStack}</Typography.Text>
              </Flex>
            </Card>

            <Button block onClick={error.reset}>
              Попробовать снова
            </Button>
          </Flex>
        ) : (
          <Button block onClick={error.reset}>
            Попробовать снова
          </Button>
        )
      }
    />
  );
};
