import { captureException } from '@sentry/react';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { EStorageKeys } from '@/configs';

import { ssoRedirect } from '@/utils';

export type TAPIErrors = { [key: string]: string[] };

export type TAPIErrorsResponse = {
  errors: TAPIErrors;
};

const axiosDefaults = {
  baseURL: import.meta.env.VITE_BACKEND_API_ENDPOINT + '/api/',
  headers: {
    'Content-Type': 'application/json',
  },
};
const http = axios.create(axiosDefaults);
const customHttp = axios.create({});

http.interceptors.request.use(
  function (config) {
    const token = Cookies.get(EStorageKeys.AUTH_TOKEN);
    const localToken = import.meta.env.VITE_TEMP_AUTH_TOKEN;
    const tokenToSend = localToken ? localToken : token;

    if (tokenToSend) {
      config.headers.Authorization = `Bearer ${tokenToSend}`;
    }

    return config;
  },
  function (error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  },
);
http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError): Promise<AxiosError> {
    const isLocal = import.meta.env.VITE_IS_LOCAL_DEVELOPMENT;

    if (error.response?.status === 500) {
      captureException(error);
    } else if (error.response?.status === 401 && !isLocal) {
      ssoRedirect();
    }
    return Promise.reject(error);
  },
);

const httpWrapper = {
  get: async <T>(url: string, config = {}): Promise<T> => {
    const response = await http.get<T>(url, config);
    return response.data;
  },
  post: async <T>(url: string, data?: unknown, config = {}): Promise<T> => {
    const response = await http.post<T>(url, data, config);
    return response.data;
  },
  delete: async <T>(url: string, config = {}): Promise<T> => {
    const response = await http.delete<T>(url, config);
    return response.data;
  },
};

export { httpWrapper, customHttp };
