import { QueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { portfolioQueries } from '@/api/endpoints/portfolio';

import {
  PortfolioPage,
  PortfolioPending,
} from '@/pages/portfolios/portfolio/index';

export const Route = createFileRoute('/_default/portfolio/$portfolioId')({
  component: PortfolioPage,
  pendingComponent: PortfolioPending,
  loader: async ({
    context,
    params,
  }: {
    context: { queryClient: QueryClient };
    params: { portfolioId: string };
  }) => {
    return context.queryClient.ensureQueryData(
      portfolioQueries.detail(params.portfolioId),
    );
  },
  onEnter: ({ loaderData }) => {
    if (loaderData) {
      document.title =
        'Портфолио - ' +
        loaderData?.name?.toLocaleLowerCase() +
        ' | InStudy 2.0';
    }
  },
});
