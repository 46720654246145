import { Alert, Button, Flex, Typography } from 'antd';

import { useStyles } from './styles.ts';

import { useAuthStore } from '@/stores/auth';

import { ssoRedirect } from '@/utils';

import IcSSoLogo from '@/assets/icons/ic_sso-logo-light.svg?react';

export const SsoForm = () => {
  const { Text } = Typography;
  const { styles } = useStyles();

  const error = useAuthStore.use.error();

  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={8} align='center'>
        <Button type='primary' block onClick={ssoRedirect}>
          Войти через InStudyID
        </Button>

        <Flex gap={4} align='center'>
          <Text type='secondary' className={styles.text}>
            Нет аккаунта?
          </Text>

          <Button
            type='link'
            className={styles.registryButton}
            href={import.meta.env.VITE_SSO_URI + '/auth/register'}
          >
            Регистрация
          </Button>
        </Flex>
      </Flex>

      {error && (
        <Alert
          type='error'
          message={error}
          style={{ whiteSpace: 'pre-line' }}
        />
      )}

      <Flex gap={4} vertical align='center' justify='center'>
        <div>
          <Text type='secondary' className={styles.text}>
            Выйти из InStudyID можно в
          </Text>
        </div>

        <a href={import.meta.env.VITE_SSO_URI + '/home'}>
          <Button icon={<IcSSoLogo />} className={styles.ssoButton} type='text'>
            Аккаунте SSO
          </Button>
        </a>
      </Flex>
    </Flex>
  );
};
