import { createFileRoute } from '@tanstack/react-router';

import {
  DisciplineWorksPending,
  DisciplineWorksPage,
} from '@/pages/education/discipline/works/index';

export const Route = createFileRoute(
  '/_default/education/$disciplineId_/works',
)({
  component: DisciplineWorksPage,
  pendingComponent: DisciplineWorksPending,
  onEnter: () => {
    document.title = 'Список работ по дисциплине | InStudy 2.0';
  },
});
