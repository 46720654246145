import { QueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { assistantQueries } from '@/api/endpoints/assistant';

import { AssistantPage } from '@/pages/assistant';

export const Route = createFileRoute('/_clear/assistant')({
  component: AssistantPage,
  onEnter: () => {
    document.title = 'Ассистент | InStudy 2.0';
  },
  loader: async ({ context }: { context: { queryClient: QueryClient } }) => {
    return await context.queryClient.ensureQueryData(
      assistantQueries.chatHistory(),
    );
  },
});
