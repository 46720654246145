import { createFileRoute } from '@tanstack/react-router';

import { PassPage, PassPending } from '@/pages/pass';

export const Route = createFileRoute('/_default/pass')({
  component: PassPage,
  pendingComponent: PassPending,
  onEnter: () => {
    document.title = 'Электронный пропуск | InStudy 2.0';
  },
});
