import { queryOptions } from '@tanstack/react-query';

import { httpWrapper } from '@/api/client';

export type TFinanceInfoDTO = {
  debt: number;
  paidSemesters: number;
  paidSum: number;
  totalSemesters: number;
  totalSum: number;
  nextPayment: {
    date: string | null;
    sum: number | null;
  } | null;
  percentages: {
    type: 'paidSum' | 'debt' | 'nextPayment' | 'totalSum';
    percents: number;
  }[];
};
export type TFinanceActiveDiscountDTO = {
  discountId: string;
  discountName: string;
  discountSize: number;
  discountType: 'fixed' | 'percent';
  semesterBegin: number;
  semesterEnd: number;
};
export type TPaymentHistoryDTO = {
  date: string;
  sum: number;
  type: 'receipt' | 'refund';
};
export type TPaymentScheduleDTO = {
  current: {
    nextPaymentDate: string;
    recommendedPayment: number;
  };
  semesters: TPaymentScheduleSemesterDTO[];
};
export type TPaymentScheduleSemesterDTO = {
  debt: number;
  isFullyPaid: boolean;
  paidAmount: number;
  semester: number;
  totalRequired: number;
  payments: {
    date: string;
    isPaid: boolean;
    sum: number;
  }[];
};
export type TPaymentInvoiceDTO = {
  link: string;
  date: string;
  summ: string;
};
export type TFinanceDTO = {
  currentSemester: number;
  expectedPayment: number;
  isVacation: boolean;
  financeInfo: TFinanceInfoDTO;
  paymentsHistory: TPaymentHistoryDTO[];
  paymentsSchedule: TPaymentScheduleDTO;
  invoices: TPaymentInvoiceDTO[];
  activeDiscounts: TFinanceActiveDiscountDTO[];
};

const fetchFinanceWidget = () => {
  return httpWrapper.get<TFinanceInfoDTO>('widgets/finance');
};
const fetchFinances = async () => {
  return httpWrapper.get<TFinanceDTO>('finances');
};

export const financeQueries = {
  widget: () =>
    queryOptions({
      queryKey: ['finance', 'widget'],
      queryFn: fetchFinanceWidget,
    }),
  all: () =>
    queryOptions({
      queryKey: ['finance', 'all'],
      queryFn: fetchFinances,
    }),
};
