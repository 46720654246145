import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { EEducationDisciplineAssentType } from '@/api/endpoints/education';

import {
  DisciplineTestsPage,
  DisciplineTestsPending,
} from '@/pages/education/discipline/tests/index';

const searchSchema = z.object({
  workType: fallback(
    z.enum([
      EEducationDisciplineAssentType.TESTS,
      EEducationDisciplineAssentType.FINAL_TESTS,
    ]),
    EEducationDisciplineAssentType.TESTS,
  ).default(EEducationDisciplineAssentType.TESTS),
});

export const Route = createFileRoute(
  '/_default/education/$disciplineId_/tests',
)({
  component: DisciplineTestsPage,
  pendingComponent: DisciplineTestsPending,
  validateSearch: zodValidator({
    schema: searchSchema,
    input: 'output',
    output: 'input',
  }),
  onEnter: ({
    loaderDeps,
  }: {
    loaderDeps: { workType: EEducationDisciplineAssentType | undefined };
  }) => {
    document.title =
      (loaderDeps.workType === EEducationDisciplineAssentType.FINAL_TESTS
        ? 'Итоговые тестирования по дисциплине'
        : 'Тестирования по дисциплине') + ' | InStudy 2.0';
  },
  loaderDeps: ({ search: { workType } }) => ({
    workType,
  }),
});
