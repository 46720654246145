import { QueryClient } from '@tanstack/react-query';
import { createFileRoute, notFound } from '@tanstack/react-router';
import { AxiosError } from 'axios';

import { financeQueries } from '@/api/endpoints/finance';

import { NotFound } from '@/components/ui/not-found';

import { FinancesPage, FinancesPending } from '@/pages/finances/index';

export const Route = createFileRoute('/_default/finances/')({
  component: FinancesPage,
  pendingComponent: FinancesPending,
  loader: async ({ context }: { context: { queryClient: QueryClient } }) => {
    const response = await context.queryClient
      .ensureQueryData(financeQueries.all())
      .catch((error: AxiosError) => {
        if (error.status === 404) {
          throw notFound();
        }
      });

    if (!response || !('financeInfo' in response) || !response.financeInfo) {
      throw notFound();
    }
  },
  onEnter: () => {
    document.title = 'Финансовая информация | InStudy 2.0';
  },
  notFoundComponent: () => (
    <NotFound
      subTitle='Попробуйте зайти позже'
      title='Данные о финансах отсутствуют'
      backLink='/'
    />
  ),
});
