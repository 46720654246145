import { createFileRoute } from '@tanstack/react-router';

import {
  ExternalArticlesPage,
  ExternalArticlesPending,
} from '@/pages/library/external-articles';

export const Route = createFileRoute('/_default/library/external-articles')({
  component: ExternalArticlesPage,
  pendingComponent: ExternalArticlesPending,
  onEnter: () => {
    document.title = 'Внешние статьи, журналы и авторефераты | InStudy 2.0';
  },
});
