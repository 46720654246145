import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { EMaterialType } from '@/api/endpoints/material';

import { MaterialsPage, MaterialsPending } from '@/pages/materials';

const searchSchema = z.object({
  path: z.string().optional(),
  type: fallback(
    z.enum([EMaterialType.CURATOR, EMaterialType.DISCIPLINE]),
    EMaterialType.DISCIPLINE,
  ).default(EMaterialType.DISCIPLINE),
  disciplineId: z.string().optional(),
});

export const Route = createFileRoute('/_default/materials')({
  component: MaterialsPage,
  pendingComponent: MaterialsPending,
  validateSearch: zodValidator({
    schema: searchSchema,
    input: 'output',
    output: 'input',
  }),
  onEnter: ({
    loaderDeps,
  }: {
    loaderDeps: { type: EMaterialType | undefined };
  }) => {
    document.title =
      (loaderDeps.type === EMaterialType.CURATOR
        ? 'Материалы куратора'
        : 'Материалы по дисциплине') + ' | InStudy 2.0';
  },
  loaderDeps: ({ search: { type } }) => ({
    type,
  }),
});
