import { createFileRoute } from '@tanstack/react-router';

import { educationQueries } from '@/api/endpoints/education';

import {
  WorkUploadPage,
  WorkUploadPending,
} from '@/pages/education/discipline/works/upload';

export const Route = createFileRoute(
  '/_default/education/$disciplineId_/works_/upload',
)({
  component: WorkUploadPage,
  pendingComponent: WorkUploadPending,
  loader: async ({ context: { queryClient }, params: { disciplineId } }) => {
    return await queryClient.ensureQueryData(
      educationQueries.workOptions(disciplineId),
    );
  },
  onEnter: () => {
    document.title = 'Загрузка работы по дисциплине | InStudy 2.0';
  },
});
