import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  layout: css`
    background-color: ${token.colorBgLayout};
    max-width: 578px;
    width: 100%;
    margin: 0 auto;
  `,
}));
