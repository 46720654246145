import { Flex, Layout, Typography } from 'antd';

import { SsoForm } from './components/sso';
import { useStyles } from './styles.ts';

import IcLogoShort from '@/assets/icons/ic_logo-short.svg?react';

export const AuthPage = () => {
  const { Title, Text } = Typography;

  const { styles } = useStyles();

  return (
    <Layout className={styles.layout}>
      <Layout.Content className='content p-x'>
        <div className={styles.wrapper}>
          <Flex vertical gap={24} className={styles.card}>
            <Flex vertical align='center' gap={24}>
              <IcLogoShort className={styles.icon} />

              <Flex vertical gap={4} align='center'>
                <Title level={2} className={styles.title}>
                  Авторизация
                </Title>

                <Text type='secondary'>Выберите способ входа в аккаунт</Text>
              </Flex>
            </Flex>

            <SsoForm />
          </Flex>
        </div>
      </Layout.Content>
    </Layout>
  );
};
