import { createFileRoute, notFound } from '@tanstack/react-router';
import { AxiosError } from 'axios';

import { educationQueries } from '@/api/endpoints/education';

import { NotFound } from '@/components/ui/not-found';

import {
  DisciplineTestPage,
  DisciplineTestPending,
} from '@/pages/education/discipline/tests/test';

export const Route = createFileRoute(
  '/_with-header/education/$disciplineId_/tests/$testId',
)({
  component: DisciplineTestPage,
  pendingComponent: DisciplineTestPending,
  notFoundComponent: () => (
    <NotFound
      title='Тест не найден'
      backLink='/education/$disciplineId/tests'
    />
  ),
  loader: async ({
    params: { disciplineId, testId },
    context: { queryClient },
  }) => {
    return await queryClient
      .ensureQueryData(educationQueries.test(disciplineId, testId))
      .catch((error: AxiosError) => {
        if (error.status === 404) {
          throw notFound();
        }
      });
  },
  onEnter: () => {
    document.title = 'Прохождение тестирования | InStudy 2.0';
  },
});
