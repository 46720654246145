export type TEducationDisciplineGradeDTO = {
  date: string;
  point: number;
  result: 'Зачтено' | 'Отлично' | 'Неаттестация';
  retake: number;
  color: boolean;
};
export type TEducationDisciplineItemDTO = {
  id: number;
  name: string;
  exam: string;
  grade: TEducationDisciplineGradeDTO | null;
  date: string | null;
  workTypes: ('work' | 'test')[];
};
export type TEducationExtraDisciplineDTO = {
  id: number;
  name: string;
};

export type TEducationTestQuestionAnswerDTO = {
  right: boolean;
  title: string;
};
export type TEducationTestQuestionDTO = {
  type: 1 | 2;
  title: string;
  answers: Record<`${number}`, TEducationTestQuestionAnswerDTO>;
};
export type TEducationTestSystemDTO = {
  percent: string;
  title: string;
  color: boolean;
};
export type TEducationTestDTO = {
  id: number;
  name: string;
  adaptive: number;
  active: number;
  final: number;
  available: boolean;
  options: {
    qlimit: number;
    tlimit: number;
    systemName: string;
    system: TEducationTestSystemDTO[];
  };
  questions: Record<`${number}`, TEducationTestQuestionDTO>;
};
export type TEducationTestFinish = {
  rtime: number;
  history: Record<string, number[]>;
};
export type TEducationTestFinishResult = {
  time: string;
  correctAnswers: number;
  totalAnswers: number;
  percentage: number;
  grade: string;
  color: boolean;
  answers: {
    question: string;
    isCorrect: boolean;
  }[];
};

export type TEducationTestItemDTO = {
  assentId: number;
  workId: number;
  name: string;
  available: boolean;
  notice: string;
  availableFrom: string;
  availableTo: string;
  testId: number;
  testName: string;
  chain: number;
  qlimit: number;
  tlimit: number;
  adaptive: number;
  result: {
    point: string | null;
    result: string;
    done: boolean | null;
    color: boolean;
  } | null;
  systemName: string;
};
export type TEducationWorkItemDTO = {
  id: number;
  workName: string;
  color: boolean | null;
  upload: string;
  comm: string;
  tupload: string;
  tcomm: string;
  teacherId: number;
  teacherName: string | null;
  point: number | null;
  result: string;
  done: boolean | null;
  tdatetime: string | null;
  date: string;
};

export type TEducationSemester = {
  currentSemester: number;
  retake: boolean;
  availableSemesters: {
    semester: number;
    hasDebts: number;
  }[];
};

export type TEducationDisciplineTeacherDTO = {
  id: number;
  name: string;
  stepen: string;
  zvanie: string;
};
export type TEducationDisciplineWebinarDTO = {
  id: number;
  name: string;
  url: string;
};
export type TEducationDisciplineAssentDTO = {
  all: number;
  done: 0;
};
export type TEducationDisciplineDTO = {
  name: string;
  examEndDate: string;
  examStartDate: string;
  exam: string;
  examType: string;
  workTypes: ('work' | 'test')[];
  assents: {
    finalTests: TEducationDisciplineAssentDTO;
    tests: TEducationDisciplineAssentDTO;
    works: TEducationDisciplineAssentDTO;
  };
  grade: TEducationDisciplineGradeDTO | null;
  teachers: TEducationDisciplineTeacherDTO[];
  videos: TEducationDisciplineWebinarDTO[];
  webinars: TEducationDisciplineWebinarDTO[];
  archiveWebinars: TEducationDisciplineWebinarDTO[];
};

export enum EEducationDisciplineAssentType {
  TESTS = 'tests',
  FINAL_TESTS = 'finalTests',
}

export type TEducationResponse = {
  disciplines: TEducationDisciplineItemDTO[];
  extraDisciplines: TEducationExtraDisciplineDTO[][];
};

export type TEducationWorkOptionsResponse = {
  teachers: Omit<TEducationDisciplineTeacherDTO, 'zvanie' | 'stepen'>[];
  works: { assentId: number; workId: number; name: string }[];
};
