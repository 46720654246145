import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { App, Layout } from 'antd';
import { useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';

import { MessageOutlined } from '@ant-design/icons';

import { routeTree } from './routeTree.gen.ts';

import { ErrorBoundary } from '@/components/ui/error';
import { NotFound } from '@/components/ui/not-found';

import { withProviders } from '@/providers';

import { EAuthStatus, useAuthStore } from '@/stores/auth';

import { useWebSocket } from '@/hooks';
import { MyRouterContext } from '@/routes/__root.tsx';
import 'swiper/css';
import '@/assets/scripts';
import '@/assets/styles/index.scss';

const router = createRouter({
  routeTree,
  context: {
    queryClient: undefined!,
    profileStore: undefined!,
  },
  scrollRestoration: true,
  scrollToTopSelectors: ['.content'],
  defaultPendingMs: 100,
  defaultNotFoundComponent: () => <NotFound />,
  defaultErrorComponent: (error) => (
    <Layout.Content className='content p-x'>
      <ErrorBoundary
        on401={() => {
          const updateStatus = useAuthStore.use.updateStatus();
          const updateToken = useAuthStore.use.updateToken();
          updateStatus(EAuthStatus.INVALID_TOKEN);
          updateToken(null);
        }}
        error={error}
      />
    </Layout.Content>
  ),
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.globalHandlersIntegration(),
    ],
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    normalizeDepth: 5,
    beforeSend(event) {
      if (event.level === 'error' || event.level === 'fatal') {
        return event;
      }
      return null;
    },
  });
}

const MyApp = () => {
  const { notification } = App.useApp();
  const queryClient = useQueryClient();
  const context: MyRouterContext = {
    queryClient,
    profileStore: useAuthStore(),
  };
  const profileId = useAuthStore().currentProfile.id;
  const { subscribe, addMessageHandler } = useWebSocket();

  useEffect(() => {
    const channel = `private-notifications.${profileId}`;
    subscribe(channel);

    const handleNotification = (message: { event: string; data: string }) => {
      if (message.event === 'notification') {
        const data = JSON.parse(message.data);
        if (data.newMessage && !window.location.pathname.includes('chats')) {
          notification.info({
            message: 'Новое сообщение!',
            description: `От пользователя ${data.newMessage.partner.fullName}`,
            showProgress: true,
            icon: <MessageOutlined className='icon' />,
          });
        }
      }
    };

    addMessageHandler('notification', handleNotification);
  }, [profileId, subscribe, addMessageHandler, notification]);

  return (
    <>
      <RouterProvider router={router} context={context} />
      {process.env.NODE_ENV === 'production' && (
        <YMInitializer
          accounts={[99617386]}
          attrs={{}}
          options={{
            webvisor: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
          }}
          version='2'
        />
      )}
    </>
  );
};

const AppWithProviders = withProviders(MyApp);
export default AppWithProviders;
