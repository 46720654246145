/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as FeedbackImport } from './routes/feedback';
import { Route as WithHeaderImport } from './routes/_with-header';
import { Route as DefaultImport } from './routes/_default';
import { Route as IndexImport } from './routes/index';
import { Route as DefaultServiceImport } from './routes/_default/service';
import { Route as DefaultPassImport } from './routes/_default/pass';
import { Route as DefaultMaterialsImport } from './routes/_default/materials';
import { Route as DefaultCuratorImport } from './routes/_default/curator';
import { Route as ClearAssistantImport } from './routes/_clear/assistant';
import { Route as DefaultScheduleIndexImport } from './routes/_default/schedule/index';
import { Route as DefaultPortfolioIndexImport } from './routes/_default/portfolio/index';
import { Route as DefaultLibraryIndexImport } from './routes/_default/library/index';
import { Route as DefaultFinancesIndexImport } from './routes/_default/finances/index';
import { Route as DefaultEducationIndexImport } from './routes/_default/education/index';
import { Route as DefaultChatsIndexImport } from './routes/_default/chats/index';
import { Route as DefaultAnnouncesIndexImport } from './routes/_default/announces/index';
import { Route as DefaultScheduleLessonIdImport } from './routes/_default/schedule/$lessonId';
import { Route as DefaultPortfolioPortfolioIdImport } from './routes/_default/portfolio/$portfolioId';
import { Route as DefaultLibraryExternalResourcesImport } from './routes/_default/library/external-resources';
import { Route as DefaultLibraryExternalArticlesImport } from './routes/_default/library/external-articles';
import { Route as DefaultFinancesInvoiceFormImport } from './routes/_default/finances/invoice-form';
import { Route as DefaultEducationDisciplineIdImport } from './routes/_default/education/$disciplineId';
import { Route as DefaultAnnouncesAnnounceIdImport } from './routes/_default/announces/$announceId';
import { Route as ClearChatsChatIdImport } from './routes/_clear/chats/$chatId';
import { Route as DefaultPortfolioPortfolioIdUploadImport } from './routes/_default/portfolio/$portfolioId_.upload';
import { Route as DefaultEducationDisciplineIdWorksImport } from './routes/_default/education/$disciplineId_/works';
import { Route as DefaultEducationDisciplineIdTestsImport } from './routes/_default/education/$disciplineId_/tests';
import { Route as WithHeaderEducationDisciplineIdTestsTestIdImport } from './routes/_with-header/education/$disciplineId_/tests/$testId';
import { Route as DefaultEducationDisciplineIdWorksUploadImport } from './routes/_default/education/$disciplineId_/works_.upload';

// Create/Update Routes

const FeedbackRoute = FeedbackImport.update({
  id: '/feedback',
  path: '/feedback',
  getParentRoute: () => rootRoute,
} as any);

const WithHeaderRoute = WithHeaderImport.update({
  id: '/_with-header',
  getParentRoute: () => rootRoute,
} as any);

const DefaultRoute = DefaultImport.update({
  id: '/_default',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const DefaultServiceRoute = DefaultServiceImport.update({
  id: '/service',
  path: '/service',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultPassRoute = DefaultPassImport.update({
  id: '/pass',
  path: '/pass',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultMaterialsRoute = DefaultMaterialsImport.update({
  id: '/materials',
  path: '/materials',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultCuratorRoute = DefaultCuratorImport.update({
  id: '/curator',
  path: '/curator',
  getParentRoute: () => DefaultRoute,
} as any);

const ClearAssistantRoute = ClearAssistantImport.update({
  id: '/_clear/assistant',
  path: '/assistant',
  getParentRoute: () => rootRoute,
} as any);

const DefaultScheduleIndexRoute = DefaultScheduleIndexImport.update({
  id: '/schedule/',
  path: '/schedule/',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultPortfolioIndexRoute = DefaultPortfolioIndexImport.update({
  id: '/portfolio/',
  path: '/portfolio/',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultLibraryIndexRoute = DefaultLibraryIndexImport.update({
  id: '/library/',
  path: '/library/',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultFinancesIndexRoute = DefaultFinancesIndexImport.update({
  id: '/finances/',
  path: '/finances/',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultEducationIndexRoute = DefaultEducationIndexImport.update({
  id: '/education/',
  path: '/education/',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultChatsIndexRoute = DefaultChatsIndexImport.update({
  id: '/chats/',
  path: '/chats/',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultAnnouncesIndexRoute = DefaultAnnouncesIndexImport.update({
  id: '/announces/',
  path: '/announces/',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultScheduleLessonIdRoute = DefaultScheduleLessonIdImport.update({
  id: '/schedule/$lessonId',
  path: '/schedule/$lessonId',
  getParentRoute: () => DefaultRoute,
} as any);

const DefaultPortfolioPortfolioIdRoute =
  DefaultPortfolioPortfolioIdImport.update({
    id: '/portfolio/$portfolioId',
    path: '/portfolio/$portfolioId',
    getParentRoute: () => DefaultRoute,
  } as any);

const DefaultLibraryExternalResourcesRoute =
  DefaultLibraryExternalResourcesImport.update({
    id: '/library/external-resources',
    path: '/library/external-resources',
    getParentRoute: () => DefaultRoute,
  } as any);

const DefaultLibraryExternalArticlesRoute =
  DefaultLibraryExternalArticlesImport.update({
    id: '/library/external-articles',
    path: '/library/external-articles',
    getParentRoute: () => DefaultRoute,
  } as any);

const DefaultFinancesInvoiceFormRoute = DefaultFinancesInvoiceFormImport.update(
  {
    id: '/finances/invoice-form',
    path: '/finances/invoice-form',
    getParentRoute: () => DefaultRoute,
  } as any,
);

const DefaultEducationDisciplineIdRoute =
  DefaultEducationDisciplineIdImport.update({
    id: '/education/$disciplineId',
    path: '/education/$disciplineId',
    getParentRoute: () => DefaultRoute,
  } as any);

const DefaultAnnouncesAnnounceIdRoute = DefaultAnnouncesAnnounceIdImport.update(
  {
    id: '/announces/$announceId',
    path: '/announces/$announceId',
    getParentRoute: () => DefaultRoute,
  } as any,
);

const ClearChatsChatIdRoute = ClearChatsChatIdImport.update({
  id: '/_clear/chats/$chatId',
  path: '/chats/$chatId',
  getParentRoute: () => rootRoute,
} as any);

const DefaultPortfolioPortfolioIdUploadRoute =
  DefaultPortfolioPortfolioIdUploadImport.update({
    id: '/portfolio/$portfolioId_/upload',
    path: '/portfolio/$portfolioId/upload',
    getParentRoute: () => DefaultRoute,
  } as any);

const DefaultEducationDisciplineIdWorksRoute =
  DefaultEducationDisciplineIdWorksImport.update({
    id: '/education/$disciplineId_/works',
    path: '/education/$disciplineId/works',
    getParentRoute: () => DefaultRoute,
  } as any);

const DefaultEducationDisciplineIdTestsRoute =
  DefaultEducationDisciplineIdTestsImport.update({
    id: '/education/$disciplineId_/tests',
    path: '/education/$disciplineId/tests',
    getParentRoute: () => DefaultRoute,
  } as any);

const WithHeaderEducationDisciplineIdTestsTestIdRoute =
  WithHeaderEducationDisciplineIdTestsTestIdImport.update({
    id: '/education/$disciplineId_/tests/$testId',
    path: '/education/$disciplineId/tests/$testId',
    getParentRoute: () => WithHeaderRoute,
  } as any);

const DefaultEducationDisciplineIdWorksUploadRoute =
  DefaultEducationDisciplineIdWorksUploadImport.update({
    id: '/education/$disciplineId_/works_/upload',
    path: '/education/$disciplineId/works/upload',
    getParentRoute: () => DefaultRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/_default': {
      id: '/_default';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof DefaultImport;
      parentRoute: typeof rootRoute;
    };
    '/_with-header': {
      id: '/_with-header';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof WithHeaderImport;
      parentRoute: typeof rootRoute;
    };
    '/feedback': {
      id: '/feedback';
      path: '/feedback';
      fullPath: '/feedback';
      preLoaderRoute: typeof FeedbackImport;
      parentRoute: typeof rootRoute;
    };
    '/_clear/assistant': {
      id: '/_clear/assistant';
      path: '/assistant';
      fullPath: '/assistant';
      preLoaderRoute: typeof ClearAssistantImport;
      parentRoute: typeof rootRoute;
    };
    '/_default/curator': {
      id: '/_default/curator';
      path: '/curator';
      fullPath: '/curator';
      preLoaderRoute: typeof DefaultCuratorImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/materials': {
      id: '/_default/materials';
      path: '/materials';
      fullPath: '/materials';
      preLoaderRoute: typeof DefaultMaterialsImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/pass': {
      id: '/_default/pass';
      path: '/pass';
      fullPath: '/pass';
      preLoaderRoute: typeof DefaultPassImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/service': {
      id: '/_default/service';
      path: '/service';
      fullPath: '/service';
      preLoaderRoute: typeof DefaultServiceImport;
      parentRoute: typeof DefaultImport;
    };
    '/_clear/chats/$chatId': {
      id: '/_clear/chats/$chatId';
      path: '/chats/$chatId';
      fullPath: '/chats/$chatId';
      preLoaderRoute: typeof ClearChatsChatIdImport;
      parentRoute: typeof rootRoute;
    };
    '/_default/announces/$announceId': {
      id: '/_default/announces/$announceId';
      path: '/announces/$announceId';
      fullPath: '/announces/$announceId';
      preLoaderRoute: typeof DefaultAnnouncesAnnounceIdImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/education/$disciplineId': {
      id: '/_default/education/$disciplineId';
      path: '/education/$disciplineId';
      fullPath: '/education/$disciplineId';
      preLoaderRoute: typeof DefaultEducationDisciplineIdImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/finances/invoice-form': {
      id: '/_default/finances/invoice-form';
      path: '/finances/invoice-form';
      fullPath: '/finances/invoice-form';
      preLoaderRoute: typeof DefaultFinancesInvoiceFormImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/library/external-articles': {
      id: '/_default/library/external-articles';
      path: '/library/external-articles';
      fullPath: '/library/external-articles';
      preLoaderRoute: typeof DefaultLibraryExternalArticlesImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/library/external-resources': {
      id: '/_default/library/external-resources';
      path: '/library/external-resources';
      fullPath: '/library/external-resources';
      preLoaderRoute: typeof DefaultLibraryExternalResourcesImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/portfolio/$portfolioId': {
      id: '/_default/portfolio/$portfolioId';
      path: '/portfolio/$portfolioId';
      fullPath: '/portfolio/$portfolioId';
      preLoaderRoute: typeof DefaultPortfolioPortfolioIdImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/schedule/$lessonId': {
      id: '/_default/schedule/$lessonId';
      path: '/schedule/$lessonId';
      fullPath: '/schedule/$lessonId';
      preLoaderRoute: typeof DefaultScheduleLessonIdImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/announces/': {
      id: '/_default/announces/';
      path: '/announces';
      fullPath: '/announces';
      preLoaderRoute: typeof DefaultAnnouncesIndexImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/chats/': {
      id: '/_default/chats/';
      path: '/chats';
      fullPath: '/chats';
      preLoaderRoute: typeof DefaultChatsIndexImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/education/': {
      id: '/_default/education/';
      path: '/education';
      fullPath: '/education';
      preLoaderRoute: typeof DefaultEducationIndexImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/finances/': {
      id: '/_default/finances/';
      path: '/finances';
      fullPath: '/finances';
      preLoaderRoute: typeof DefaultFinancesIndexImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/library/': {
      id: '/_default/library/';
      path: '/library';
      fullPath: '/library';
      preLoaderRoute: typeof DefaultLibraryIndexImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/portfolio/': {
      id: '/_default/portfolio/';
      path: '/portfolio';
      fullPath: '/portfolio';
      preLoaderRoute: typeof DefaultPortfolioIndexImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/schedule/': {
      id: '/_default/schedule/';
      path: '/schedule';
      fullPath: '/schedule';
      preLoaderRoute: typeof DefaultScheduleIndexImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/education/$disciplineId_/tests': {
      id: '/_default/education/$disciplineId_/tests';
      path: '/education/$disciplineId/tests';
      fullPath: '/education/$disciplineId/tests';
      preLoaderRoute: typeof DefaultEducationDisciplineIdTestsImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/education/$disciplineId_/works': {
      id: '/_default/education/$disciplineId_/works';
      path: '/education/$disciplineId/works';
      fullPath: '/education/$disciplineId/works';
      preLoaderRoute: typeof DefaultEducationDisciplineIdWorksImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/portfolio/$portfolioId_/upload': {
      id: '/_default/portfolio/$portfolioId_/upload';
      path: '/portfolio/$portfolioId/upload';
      fullPath: '/portfolio/$portfolioId/upload';
      preLoaderRoute: typeof DefaultPortfolioPortfolioIdUploadImport;
      parentRoute: typeof DefaultImport;
    };
    '/_default/education/$disciplineId_/works_/upload': {
      id: '/_default/education/$disciplineId_/works_/upload';
      path: '/education/$disciplineId/works/upload';
      fullPath: '/education/$disciplineId/works/upload';
      preLoaderRoute: typeof DefaultEducationDisciplineIdWorksUploadImport;
      parentRoute: typeof DefaultImport;
    };
    '/_with-header/education/$disciplineId_/tests/$testId': {
      id: '/_with-header/education/$disciplineId_/tests/$testId';
      path: '/education/$disciplineId/tests/$testId';
      fullPath: '/education/$disciplineId/tests/$testId';
      preLoaderRoute: typeof WithHeaderEducationDisciplineIdTestsTestIdImport;
      parentRoute: typeof WithHeaderImport;
    };
  }
}

// Create and export the route tree

interface DefaultRouteChildren {
  DefaultCuratorRoute: typeof DefaultCuratorRoute;
  DefaultMaterialsRoute: typeof DefaultMaterialsRoute;
  DefaultPassRoute: typeof DefaultPassRoute;
  DefaultServiceRoute: typeof DefaultServiceRoute;
  DefaultAnnouncesAnnounceIdRoute: typeof DefaultAnnouncesAnnounceIdRoute;
  DefaultEducationDisciplineIdRoute: typeof DefaultEducationDisciplineIdRoute;
  DefaultFinancesInvoiceFormRoute: typeof DefaultFinancesInvoiceFormRoute;
  DefaultLibraryExternalArticlesRoute: typeof DefaultLibraryExternalArticlesRoute;
  DefaultLibraryExternalResourcesRoute: typeof DefaultLibraryExternalResourcesRoute;
  DefaultPortfolioPortfolioIdRoute: typeof DefaultPortfolioPortfolioIdRoute;
  DefaultScheduleLessonIdRoute: typeof DefaultScheduleLessonIdRoute;
  DefaultAnnouncesIndexRoute: typeof DefaultAnnouncesIndexRoute;
  DefaultChatsIndexRoute: typeof DefaultChatsIndexRoute;
  DefaultEducationIndexRoute: typeof DefaultEducationIndexRoute;
  DefaultFinancesIndexRoute: typeof DefaultFinancesIndexRoute;
  DefaultLibraryIndexRoute: typeof DefaultLibraryIndexRoute;
  DefaultPortfolioIndexRoute: typeof DefaultPortfolioIndexRoute;
  DefaultScheduleIndexRoute: typeof DefaultScheduleIndexRoute;
  DefaultEducationDisciplineIdTestsRoute: typeof DefaultEducationDisciplineIdTestsRoute;
  DefaultEducationDisciplineIdWorksRoute: typeof DefaultEducationDisciplineIdWorksRoute;
  DefaultPortfolioPortfolioIdUploadRoute: typeof DefaultPortfolioPortfolioIdUploadRoute;
  DefaultEducationDisciplineIdWorksUploadRoute: typeof DefaultEducationDisciplineIdWorksUploadRoute;
}

const DefaultRouteChildren: DefaultRouteChildren = {
  DefaultCuratorRoute: DefaultCuratorRoute,
  DefaultMaterialsRoute: DefaultMaterialsRoute,
  DefaultPassRoute: DefaultPassRoute,
  DefaultServiceRoute: DefaultServiceRoute,
  DefaultAnnouncesAnnounceIdRoute: DefaultAnnouncesAnnounceIdRoute,
  DefaultEducationDisciplineIdRoute: DefaultEducationDisciplineIdRoute,
  DefaultFinancesInvoiceFormRoute: DefaultFinancesInvoiceFormRoute,
  DefaultLibraryExternalArticlesRoute: DefaultLibraryExternalArticlesRoute,
  DefaultLibraryExternalResourcesRoute: DefaultLibraryExternalResourcesRoute,
  DefaultPortfolioPortfolioIdRoute: DefaultPortfolioPortfolioIdRoute,
  DefaultScheduleLessonIdRoute: DefaultScheduleLessonIdRoute,
  DefaultAnnouncesIndexRoute: DefaultAnnouncesIndexRoute,
  DefaultChatsIndexRoute: DefaultChatsIndexRoute,
  DefaultEducationIndexRoute: DefaultEducationIndexRoute,
  DefaultFinancesIndexRoute: DefaultFinancesIndexRoute,
  DefaultLibraryIndexRoute: DefaultLibraryIndexRoute,
  DefaultPortfolioIndexRoute: DefaultPortfolioIndexRoute,
  DefaultScheduleIndexRoute: DefaultScheduleIndexRoute,
  DefaultEducationDisciplineIdTestsRoute:
    DefaultEducationDisciplineIdTestsRoute,
  DefaultEducationDisciplineIdWorksRoute:
    DefaultEducationDisciplineIdWorksRoute,
  DefaultPortfolioPortfolioIdUploadRoute:
    DefaultPortfolioPortfolioIdUploadRoute,
  DefaultEducationDisciplineIdWorksUploadRoute:
    DefaultEducationDisciplineIdWorksUploadRoute,
};

const DefaultRouteWithChildren =
  DefaultRoute._addFileChildren(DefaultRouteChildren);

interface WithHeaderRouteChildren {
  WithHeaderEducationDisciplineIdTestsTestIdRoute: typeof WithHeaderEducationDisciplineIdTestsTestIdRoute;
}

const WithHeaderRouteChildren: WithHeaderRouteChildren = {
  WithHeaderEducationDisciplineIdTestsTestIdRoute:
    WithHeaderEducationDisciplineIdTestsTestIdRoute,
};

const WithHeaderRouteWithChildren = WithHeaderRoute._addFileChildren(
  WithHeaderRouteChildren,
);

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '': typeof WithHeaderRouteWithChildren;
  '/feedback': typeof FeedbackRoute;
  '/assistant': typeof ClearAssistantRoute;
  '/curator': typeof DefaultCuratorRoute;
  '/materials': typeof DefaultMaterialsRoute;
  '/pass': typeof DefaultPassRoute;
  '/service': typeof DefaultServiceRoute;
  '/chats/$chatId': typeof ClearChatsChatIdRoute;
  '/announces/$announceId': typeof DefaultAnnouncesAnnounceIdRoute;
  '/education/$disciplineId': typeof DefaultEducationDisciplineIdRoute;
  '/finances/invoice-form': typeof DefaultFinancesInvoiceFormRoute;
  '/library/external-articles': typeof DefaultLibraryExternalArticlesRoute;
  '/library/external-resources': typeof DefaultLibraryExternalResourcesRoute;
  '/portfolio/$portfolioId': typeof DefaultPortfolioPortfolioIdRoute;
  '/schedule/$lessonId': typeof DefaultScheduleLessonIdRoute;
  '/announces': typeof DefaultAnnouncesIndexRoute;
  '/chats': typeof DefaultChatsIndexRoute;
  '/education': typeof DefaultEducationIndexRoute;
  '/finances': typeof DefaultFinancesIndexRoute;
  '/library': typeof DefaultLibraryIndexRoute;
  '/portfolio': typeof DefaultPortfolioIndexRoute;
  '/schedule': typeof DefaultScheduleIndexRoute;
  '/education/$disciplineId/tests': typeof DefaultEducationDisciplineIdTestsRoute;
  '/education/$disciplineId/works': typeof DefaultEducationDisciplineIdWorksRoute;
  '/portfolio/$portfolioId/upload': typeof DefaultPortfolioPortfolioIdUploadRoute;
  '/education/$disciplineId/works/upload': typeof DefaultEducationDisciplineIdWorksUploadRoute;
  '/education/$disciplineId/tests/$testId': typeof WithHeaderEducationDisciplineIdTestsTestIdRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '': typeof WithHeaderRouteWithChildren;
  '/feedback': typeof FeedbackRoute;
  '/assistant': typeof ClearAssistantRoute;
  '/curator': typeof DefaultCuratorRoute;
  '/materials': typeof DefaultMaterialsRoute;
  '/pass': typeof DefaultPassRoute;
  '/service': typeof DefaultServiceRoute;
  '/chats/$chatId': typeof ClearChatsChatIdRoute;
  '/announces/$announceId': typeof DefaultAnnouncesAnnounceIdRoute;
  '/education/$disciplineId': typeof DefaultEducationDisciplineIdRoute;
  '/finances/invoice-form': typeof DefaultFinancesInvoiceFormRoute;
  '/library/external-articles': typeof DefaultLibraryExternalArticlesRoute;
  '/library/external-resources': typeof DefaultLibraryExternalResourcesRoute;
  '/portfolio/$portfolioId': typeof DefaultPortfolioPortfolioIdRoute;
  '/schedule/$lessonId': typeof DefaultScheduleLessonIdRoute;
  '/announces': typeof DefaultAnnouncesIndexRoute;
  '/chats': typeof DefaultChatsIndexRoute;
  '/education': typeof DefaultEducationIndexRoute;
  '/finances': typeof DefaultFinancesIndexRoute;
  '/library': typeof DefaultLibraryIndexRoute;
  '/portfolio': typeof DefaultPortfolioIndexRoute;
  '/schedule': typeof DefaultScheduleIndexRoute;
  '/education/$disciplineId/tests': typeof DefaultEducationDisciplineIdTestsRoute;
  '/education/$disciplineId/works': typeof DefaultEducationDisciplineIdWorksRoute;
  '/portfolio/$portfolioId/upload': typeof DefaultPortfolioPortfolioIdUploadRoute;
  '/education/$disciplineId/works/upload': typeof DefaultEducationDisciplineIdWorksUploadRoute;
  '/education/$disciplineId/tests/$testId': typeof WithHeaderEducationDisciplineIdTestsTestIdRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/_default': typeof DefaultRouteWithChildren;
  '/_with-header': typeof WithHeaderRouteWithChildren;
  '/feedback': typeof FeedbackRoute;
  '/_clear/assistant': typeof ClearAssistantRoute;
  '/_default/curator': typeof DefaultCuratorRoute;
  '/_default/materials': typeof DefaultMaterialsRoute;
  '/_default/pass': typeof DefaultPassRoute;
  '/_default/service': typeof DefaultServiceRoute;
  '/_clear/chats/$chatId': typeof ClearChatsChatIdRoute;
  '/_default/announces/$announceId': typeof DefaultAnnouncesAnnounceIdRoute;
  '/_default/education/$disciplineId': typeof DefaultEducationDisciplineIdRoute;
  '/_default/finances/invoice-form': typeof DefaultFinancesInvoiceFormRoute;
  '/_default/library/external-articles': typeof DefaultLibraryExternalArticlesRoute;
  '/_default/library/external-resources': typeof DefaultLibraryExternalResourcesRoute;
  '/_default/portfolio/$portfolioId': typeof DefaultPortfolioPortfolioIdRoute;
  '/_default/schedule/$lessonId': typeof DefaultScheduleLessonIdRoute;
  '/_default/announces/': typeof DefaultAnnouncesIndexRoute;
  '/_default/chats/': typeof DefaultChatsIndexRoute;
  '/_default/education/': typeof DefaultEducationIndexRoute;
  '/_default/finances/': typeof DefaultFinancesIndexRoute;
  '/_default/library/': typeof DefaultLibraryIndexRoute;
  '/_default/portfolio/': typeof DefaultPortfolioIndexRoute;
  '/_default/schedule/': typeof DefaultScheduleIndexRoute;
  '/_default/education/$disciplineId_/tests': typeof DefaultEducationDisciplineIdTestsRoute;
  '/_default/education/$disciplineId_/works': typeof DefaultEducationDisciplineIdWorksRoute;
  '/_default/portfolio/$portfolioId_/upload': typeof DefaultPortfolioPortfolioIdUploadRoute;
  '/_default/education/$disciplineId_/works_/upload': typeof DefaultEducationDisciplineIdWorksUploadRoute;
  '/_with-header/education/$disciplineId_/tests/$testId': typeof WithHeaderEducationDisciplineIdTestsTestIdRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | ''
    | '/feedback'
    | '/assistant'
    | '/curator'
    | '/materials'
    | '/pass'
    | '/service'
    | '/chats/$chatId'
    | '/announces/$announceId'
    | '/education/$disciplineId'
    | '/finances/invoice-form'
    | '/library/external-articles'
    | '/library/external-resources'
    | '/portfolio/$portfolioId'
    | '/schedule/$lessonId'
    | '/announces'
    | '/chats'
    | '/education'
    | '/finances'
    | '/library'
    | '/portfolio'
    | '/schedule'
    | '/education/$disciplineId/tests'
    | '/education/$disciplineId/works'
    | '/portfolio/$portfolioId/upload'
    | '/education/$disciplineId/works/upload'
    | '/education/$disciplineId/tests/$testId';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | ''
    | '/feedback'
    | '/assistant'
    | '/curator'
    | '/materials'
    | '/pass'
    | '/service'
    | '/chats/$chatId'
    | '/announces/$announceId'
    | '/education/$disciplineId'
    | '/finances/invoice-form'
    | '/library/external-articles'
    | '/library/external-resources'
    | '/portfolio/$portfolioId'
    | '/schedule/$lessonId'
    | '/announces'
    | '/chats'
    | '/education'
    | '/finances'
    | '/library'
    | '/portfolio'
    | '/schedule'
    | '/education/$disciplineId/tests'
    | '/education/$disciplineId/works'
    | '/portfolio/$portfolioId/upload'
    | '/education/$disciplineId/works/upload'
    | '/education/$disciplineId/tests/$testId';
  id:
    | '__root__'
    | '/'
    | '/_default'
    | '/_with-header'
    | '/feedback'
    | '/_clear/assistant'
    | '/_default/curator'
    | '/_default/materials'
    | '/_default/pass'
    | '/_default/service'
    | '/_clear/chats/$chatId'
    | '/_default/announces/$announceId'
    | '/_default/education/$disciplineId'
    | '/_default/finances/invoice-form'
    | '/_default/library/external-articles'
    | '/_default/library/external-resources'
    | '/_default/portfolio/$portfolioId'
    | '/_default/schedule/$lessonId'
    | '/_default/announces/'
    | '/_default/chats/'
    | '/_default/education/'
    | '/_default/finances/'
    | '/_default/library/'
    | '/_default/portfolio/'
    | '/_default/schedule/'
    | '/_default/education/$disciplineId_/tests'
    | '/_default/education/$disciplineId_/works'
    | '/_default/portfolio/$portfolioId_/upload'
    | '/_default/education/$disciplineId_/works_/upload'
    | '/_with-header/education/$disciplineId_/tests/$testId';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  DefaultRoute: typeof DefaultRouteWithChildren;
  WithHeaderRoute: typeof WithHeaderRouteWithChildren;
  FeedbackRoute: typeof FeedbackRoute;
  ClearAssistantRoute: typeof ClearAssistantRoute;
  ClearChatsChatIdRoute: typeof ClearChatsChatIdRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DefaultRoute: DefaultRouteWithChildren,
  WithHeaderRoute: WithHeaderRouteWithChildren,
  FeedbackRoute: FeedbackRoute,
  ClearAssistantRoute: ClearAssistantRoute,
  ClearChatsChatIdRoute: ClearChatsChatIdRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_default",
        "/_with-header",
        "/feedback",
        "/_clear/assistant",
        "/_clear/chats/$chatId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_default": {
      "filePath": "_default.tsx",
      "children": [
        "/_default/curator",
        "/_default/materials",
        "/_default/pass",
        "/_default/service",
        "/_default/announces/$announceId",
        "/_default/education/$disciplineId",
        "/_default/finances/invoice-form",
        "/_default/library/external-articles",
        "/_default/library/external-resources",
        "/_default/portfolio/$portfolioId",
        "/_default/schedule/$lessonId",
        "/_default/announces/",
        "/_default/chats/",
        "/_default/education/",
        "/_default/finances/",
        "/_default/library/",
        "/_default/portfolio/",
        "/_default/schedule/",
        "/_default/education/$disciplineId_/tests",
        "/_default/education/$disciplineId_/works",
        "/_default/portfolio/$portfolioId_/upload",
        "/_default/education/$disciplineId_/works_/upload"
      ]
    },
    "/_with-header": {
      "filePath": "_with-header.tsx",
      "children": [
        "/_with-header/education/$disciplineId_/tests/$testId"
      ]
    },
    "/feedback": {
      "filePath": "feedback.tsx"
    },
    "/_clear/assistant": {
      "filePath": "_clear/assistant.tsx"
    },
    "/_default/curator": {
      "filePath": "_default/curator.tsx",
      "parent": "/_default"
    },
    "/_default/materials": {
      "filePath": "_default/materials.tsx",
      "parent": "/_default"
    },
    "/_default/pass": {
      "filePath": "_default/pass.tsx",
      "parent": "/_default"
    },
    "/_default/service": {
      "filePath": "_default/service.tsx",
      "parent": "/_default"
    },
    "/_clear/chats/$chatId": {
      "filePath": "_clear/chats/$chatId.tsx"
    },
    "/_default/announces/$announceId": {
      "filePath": "_default/announces/$announceId.tsx",
      "parent": "/_default"
    },
    "/_default/education/$disciplineId": {
      "filePath": "_default/education/$disciplineId.tsx",
      "parent": "/_default"
    },
    "/_default/finances/invoice-form": {
      "filePath": "_default/finances/invoice-form.tsx",
      "parent": "/_default"
    },
    "/_default/library/external-articles": {
      "filePath": "_default/library/external-articles.tsx",
      "parent": "/_default"
    },
    "/_default/library/external-resources": {
      "filePath": "_default/library/external-resources.tsx",
      "parent": "/_default"
    },
    "/_default/portfolio/$portfolioId": {
      "filePath": "_default/portfolio/$portfolioId.tsx",
      "parent": "/_default"
    },
    "/_default/schedule/$lessonId": {
      "filePath": "_default/schedule/$lessonId.tsx",
      "parent": "/_default"
    },
    "/_default/announces/": {
      "filePath": "_default/announces/index.tsx",
      "parent": "/_default"
    },
    "/_default/chats/": {
      "filePath": "_default/chats/index.tsx",
      "parent": "/_default"
    },
    "/_default/education/": {
      "filePath": "_default/education/index.tsx",
      "parent": "/_default"
    },
    "/_default/finances/": {
      "filePath": "_default/finances/index.tsx",
      "parent": "/_default"
    },
    "/_default/library/": {
      "filePath": "_default/library/index.tsx",
      "parent": "/_default"
    },
    "/_default/portfolio/": {
      "filePath": "_default/portfolio/index.tsx",
      "parent": "/_default"
    },
    "/_default/schedule/": {
      "filePath": "_default/schedule/index.tsx",
      "parent": "/_default"
    },
    "/_default/education/$disciplineId_/tests": {
      "filePath": "_default/education/$disciplineId_/tests.tsx",
      "parent": "/_default"
    },
    "/_default/education/$disciplineId_/works": {
      "filePath": "_default/education/$disciplineId_/works.tsx",
      "parent": "/_default"
    },
    "/_default/portfolio/$portfolioId_/upload": {
      "filePath": "_default/portfolio/$portfolioId_.upload.tsx",
      "parent": "/_default"
    },
    "/_default/education/$disciplineId_/works_/upload": {
      "filePath": "_default/education/$disciplineId_/works_.upload.tsx",
      "parent": "/_default"
    },
    "/_with-header/education/$disciplineId_/tests/$testId": {
      "filePath": "_with-header/education/$disciplineId_/tests/$testId.tsx",
      "parent": "/_with-header"
    }
  }
}
ROUTE_MANIFEST_END */
