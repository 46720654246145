import { QueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { chatQueries } from '@/api/endpoints/chat/chat.ts';

import { ChatPage, ChatPending } from '@/pages/chats/chat';

export const Route = createFileRoute('/_clear/chats/$chatId')({
  component: ChatPage,
  pendingComponent: ChatPending,
  loader: async ({
    context,
    params,
  }: {
    context: { queryClient: QueryClient };
    params: { chatId: string };
  }) => {
    return await context.queryClient.ensureQueryData(
      chatQueries.messages(params.chatId),
    );
  },
  onEnter: () => {
    document.title = 'Чат | InStudy 2.0';
  },
});
