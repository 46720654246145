import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import Cookies from 'js-cookie';
import { create } from 'zustand';

import {
  TFullProfileDTO,
  TProfileItemDTO,
  TUserDTO,
} from '@/api/endpoints/auth';

export enum EAuthStatus {
  LOADING = 'loading',
  SELECT_PROFILE = 'selectProfile',
  AUTH = 'authorized',
  UNAUTH = 'unauthorized',
  INVALID_TOKEN = 'invalidToken',
}

type TState = {
  status: EAuthStatus;
  user: TUserDTO;
  currentProfile: TFullProfileDTO;
  profilesList: TProfileItemDTO[];
  error: string | null;
};

type TAction = {
  getFullName: () => string;
  updateStatus: (status: EAuthStatus) => void;
  updateCurrentProfile: (profile: TFullProfileDTO) => void;
  setProfilesList: (profiles: TProfileItemDTO[]) => void;
  updateError: (error: string | null) => void;
  setUser: (user: TUserDTO) => void;
  updateToken: (token: string | null) => void;
};

export type TAuthStore = TState & TAction;

const useAuthStoreBase = create<TAuthStore>()((set, get) => ({
  currentProfile: null as unknown as TFullProfileDTO,
  profilesList: [],
  user: null as unknown as TUserDTO,
  status: EAuthStatus.LOADING,
  error: null,

  // actions
  updateCurrentProfile: (currentProfile) => set(() => ({ currentProfile })),
  updateStatus: (status) => set(() => ({ status })),
  updateError: (error: string | null) => set(() => ({ error })),
  setProfilesList: (profiles: TProfileItemDTO[]) =>
    set(() => ({ profilesList: [...profiles] })),
  setUser: (user: TUserDTO) => set(() => ({ user })),

  // another
  getFullName: () => {
    const user = get().user;

    return (
      user.lastName +
      ' ' +
      user.firstName +
      (user.middleName ? ' ' + user.middleName : '')
    );
  },
  updateToken: (token) => {
    if (token) {
      Cookies.set('auth_token', token);
    } else {
      Cookies.remove('auth_token');
    }
  },
}));

export const useAuthStore = createSelectorFunctions(useAuthStoreBase);
