import { createFileRoute } from '@tanstack/react-router';
import { Layout } from 'antd';

import { ErrorBoundary } from '@/components/ui/error';
import { Footer } from '@/components/widgets/footer';
import { Header } from '@/components/widgets/header';

import { MainPage, MainPending } from '@/pages/main';

export const Route = createFileRoute('/')({
  component: () => (
    <>
      <Header />
      <MainPage />
      <Footer />
    </>
  ),
  pendingComponent: () => (
    <>
      <Header />
      <MainPending />
      <Footer />
    </>
  ),
  errorComponent: (err) => (
    <>
      <Header />
      <Layout.Content className='content p-x'>
        <ErrorBoundary error={err} />
      </Layout.Content>
      <Footer />
    </>
  ),
  onEnter: () => {
    document.title = 'Главная | InStudy 2.0';
  },
});
