import { queryOptions } from '@tanstack/react-query';

import { httpWrapper } from '@/api/client';

export enum EMessageFilterType {
  MY_DIALOGUES = 'my_dialogues',
  TEACHERS = 'teachers',
  CLASSMATES = 'classmates',
}

export type TChat = {
  id: number;
  name: string;
  role: Omit<string, 'Куратор'>;
  haveUnreadMessages: boolean;
  photoUrl: string | null;
};
export type TChatCurator = Omit<TChat, 'role'> & {
  email: string;
  phone: string;
  role: 'Куратор';
};

type TChatMessage = {
  date: string;
  sendByCurrentUser: boolean;
  id: number;
};
export type TChatMessageText = TChatMessage & {
  text: string;
  type: 'text';
};
export type TChatMessageFile = TChatMessage & {
  type: 'file';
  link: string;
  fileName: string;
};
type TChatPartner = {
  fullName: string;
  id: number;
  role: string;
  photoUrl: string | null;
};

export type TChatsResponse = {
  curator?: TChatCurator;
  chats: TChat[];
};
export type TChatMessagesResponse = {
  messages: (TChatMessageText | TChatMessageFile)[];
  partner: TChatPartner;
};

type TChatsSearch = {
  query?: string;
  group?: EMessageFilterType;
};

const fetchChats = (search: TChatsSearch) => {
  const searchParams = new URLSearchParams(search);
  if (search.group === EMessageFilterType.MY_DIALOGUES) {
    searchParams.delete('group');
  }
  return httpWrapper.get<TChatsResponse>('chats?' + searchParams);
};

const fetchMessages = (chatUserId: string) => {
  return httpWrapper.get<TChatMessagesResponse>(`messages/${chatUserId}`);
};

export const sendMessage = (chatUserId: string, message: string) => {
  return httpWrapper.post<TChatMessage[]>(`messages/${chatUserId}`, {
    message,
  });
};

export const sendFile = (chatUserId: string, formData: FormData) => {
  return httpWrapper.post<TChatMessage[]>(`messages/${chatUserId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const chatKeys = {
  messages: (chatUserId: string) => ['chat', 'messages', chatUserId],
};

export const chatQueries = {
  all: (search: TChatsSearch) =>
    queryOptions({
      queryKey: ['chat', 'all', search],
      queryFn: () => fetchChats(search),
      staleTime: 0,
      gcTime: 0,
    }),
  messages: (chatUserId: string) =>
    queryOptions({
      queryKey: chatKeys.messages(chatUserId),
      queryFn: () => fetchMessages(chatUserId),
    }),
};
