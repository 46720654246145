import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Button, Flex, Layout } from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { useStyles } from './styles.ts';

import { SelectProfile } from '@/components/widgets/select-profile';

import { EAuthStatus, useAuthStore } from '@/stores/auth';

const queryClient = new QueryClient();

export const SelectProfilePage = () => {
  const { styles } = useStyles();

  const updateStatus = useAuthStore.use.updateStatus();

  return (
    <QueryClientProvider client={queryClient}>
      <Layout className={styles.layout}>
        <Layout.Content className='content p-x'>
          <Flex vertical gap={48}>
            <Flex vertical gap={24}>
              <Button
                shape='round'
                icon={<ArrowLeftOutlined />}
                onClick={() => updateStatus(EAuthStatus.UNAUTH)}
                style={{ width: 'max-content' }}
              >
                Назад
              </Button>
              <SelectProfile />
            </Flex>
          </Flex>
        </Layout.Content>
      </Layout>
    </QueryClientProvider>
  );
};
