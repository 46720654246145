import { createFileRoute } from '@tanstack/react-router';

import { FeedbackPage } from '@/pages/feedback';

export const Route = createFileRoute('/feedback')({
  component: FeedbackPage,
  onEnter: () => {
    document.title = 'Обращение в техническую поддержку | InStudy 2.0';
  },
});
