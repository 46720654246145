import { TAPIErrors } from '@/api/client';

export const handleAPIFormErrors = (
  errors: TAPIErrors,
  values: Record<string, unknown>,
) => {
  return Object.keys(errors).map((key) => ({
    name: key,
    errors: [errors[key][0]],
    value: values[key],
  }));
};
export const handleAPITextErrors = (errors?: unknown): string | null => {
  if (!errors || Object.keys(errors).length === 0) {
    return null;
  }

  return Object.keys(errors)
    .map((error): string =>
      Array.isArray(errors?.[error])
        ? errors?.[error].join(', ')
        : errors?.[error],
    )
    .join('\n');
};
