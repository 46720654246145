import { queryOptions } from '@tanstack/react-query';

import { httpWrapper } from '@/api/client/client.ts';

export type TAssistantResponseItemDTO = {
  route: string;
  title: string;
};

export type TAssistantHistoryItemDTO = {
  id: number;
  query: string;
  response: TAssistantResponseItemDTO[];
  createdAt: string;
};

export const assistantRequest = (request: string) =>
  httpWrapper.post<TAssistantHistoryItemDTO>(`ai/request`, {
    query: request,
  });

const fetchAssistantHistory = () =>
  httpWrapper.get<TAssistantHistoryItemDTO[]>('ai/chat_history');

export const assistantKeys = {
  chatHistory: ['assistant', 'chatHistory'],
};

export const assistantQueries = {
  chatHistory: () =>
    queryOptions({
      queryKey: assistantKeys.chatHistory,
      queryFn: fetchAssistantHistory,
    }),
};
