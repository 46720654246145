import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Layout } from 'antd';

import { ErrorBoundary } from '@/components/ui/error';
import { Footer } from '@/components/widgets/footer';
import { Header } from '@/components/widgets/header';

export const Route = createFileRoute('/_default')({
  component: () => (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  ),
  pendingComponent: () => (
    <>
      <Header />
      <Layout.Content className='content' />
      <Footer />
    </>
  ),
  errorComponent: (err) => (
    <>
      <Header />
      <Layout.Content className='content p-x'>
        <ErrorBoundary error={err} />
      </Layout.Content>
      <Footer />
    </>
  ),
});
