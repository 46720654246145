import { queryOptions } from '@tanstack/react-query';

import { httpWrapper } from '@/api/client';

type TPortfolioDTO = {
  id: number;
  name: string;
};

type TPortfolioFileDTO = {
  id: number;
  name: string;
  file: string;
};

export type TPortfolioFilesResponse = {
  name: string;
  files: TPortfolioFileDTO[];
};

const fetchPortfolios = async () => {
  return httpWrapper.get<TPortfolioDTO[]>('portfolios');
};
const fetchPortfolioFiles = async (id: string) => {
  return httpWrapper.get<TPortfolioFilesResponse>(`portfolios/${id}`);
};
export const downloadPortfolioFile = async (file: string) => {
  return httpWrapper.post<string>(
    'download/portfolios',
    {
      file,
    },
    {
      responseType: 'blob',
    },
  );
};
export const uploadPortfolioFile = async (
  portfolioId: string,
  formData: FormData,
) => {
  return httpWrapper.post<TPortfolioFileDTO>(
    `portfolios/${portfolioId}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};
export const deletePortfolioFile = async (
  file: Pick<TPortfolioFileDTO, 'file' | 'id'>,
) => {
  return httpWrapper.post<boolean>(`portfolio/${file.id}`, {
    file: file.file,
  });
};

export const portfolioKeys = {
  all: ['portfolio', 'all'],
  detail: (portfolioId: string) => ['portfolio', portfolioId],
};

export const portfolioQueries = {
  all: () =>
    queryOptions({
      queryKey: portfolioKeys.all,
      queryFn: fetchPortfolios,
    }),
  detail: (portfolioId: string) =>
    queryOptions({
      queryKey: portfolioKeys.detail(portfolioId),
      queryFn: () => fetchPortfolioFiles(portfolioId),
    }),
};
