import { queryOptions } from '@tanstack/react-query';

import { httpWrapper } from '@/api/client';

export type TAnnounceType = 'Разработка' | 'Важно' | 'Новости' | 'Разное';

export type TAnnounceItemDTO = {
  id: number;
  title: string;
  date: string;
  type: TAnnounceType;
};
export type TAnnounceDTO = TAnnounceItemDTO & {
  text: string;
};

const fetchAnnounce = (id: string) => {
  return httpWrapper.get<TAnnounceDTO>(`announces/${id}`);
};
const fetchAnnounces = (type: TAnnounceType | 'Все', page: number) => {
  return httpWrapper.get<{
    announces: TAnnounceDTO[];
    meta: {
      currentPage: number;
      perPage: number;
      total: number;
    };
  }>(`announces?type=${type}&page=${page}`);
};

export const announceQueries = {
  detail: (announceId: string) =>
    queryOptions({
      queryKey: ['announce', 'detail', announceId],
      queryFn: () => fetchAnnounce(announceId),
    }),
  list: (type: TAnnounceType | 'Все', page: number) =>
    queryOptions({
      queryKey: ['announce', 'list', { type, page }],
      queryFn: () => fetchAnnounces(type, page),
    }),
};
