import { queryOptions } from '@tanstack/react-query';

import { httpWrapper } from '@/api/client';

export enum EMaterialType {
  DISCIPLINE = 'discipline',
  CURATOR = 'curator',
}

export type TMaterialDTO = {
  name: string;
  originalName: string;
  type: 'folder' | 'file';
  isHtml: boolean;
};

export type TMaterialSearch = {
  path?: string;
  disciplineId?: string;
  type: EMaterialType;
};

const fetchMaterials = (search: TMaterialSearch) => {
  return httpWrapper.post<TMaterialDTO[] | { content: string }>(
    `/materials`,
    search,
  );
};
export const downloadMaterialFile = (search: TMaterialSearch, path: string) => {
  const newSearch = { ...search };
  delete newSearch.path;
  return httpWrapper.post<string>(
    `download/materials`,
    {
      ...newSearch,
      path,
    },
    {
      responseType: 'blob',
    },
  );
};

export const materialQueries = {
  material: (search: TMaterialSearch) =>
    queryOptions({
      queryKey: ['material', search],
      queryFn: () => fetchMaterials(search),
    }),
};
