import { QueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { portfolioQueries } from '@/api/endpoints/portfolio';

import { PortfoliosPage, PortfoliosPending } from '@/pages/portfolios/index';

export const Route = createFileRoute('/_default/portfolio/')({
  component: PortfoliosPage,
  pendingComponent: PortfoliosPending,
  loader: async ({ context }: { context: { queryClient: QueryClient } }) => {
    return await context.queryClient.ensureQueryData(portfolioQueries.all());
  },
  onEnter: () => {
    document.title = 'Ваше портфолио | InStudy 2.0';
  },
});
