import { Button, Card, Flex, Tag, Typography } from 'antd';

import { useStyles } from './styles.ts';

import { TProfileItemDTO } from '@/api/endpoints/auth';

type TProfileItemProps = {
  profile: TProfileItemDTO;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
};

export const ProfileItem = ({
  profile,
  onClick,
  loading,
  disabled,
}: TProfileItemProps) => {
  const { styles } = useStyles();
  return (
    <Card size='small' key={profile.id}>
      <Flex vertical gap={8}>
        <Typography.Text type='secondary'>
          {profile.eduLevel}, {profile.eduForm}
        </Typography.Text>
        <Typography.Text strong className={styles.textLarge}>
          {profile.specName}
        </Typography.Text>
        <Typography.Text type='secondary'>
          Группа {profile.group}
        </Typography.Text>
        <Flex>
          <Tag>{profile.course} курс</Tag>
          <Tag>{profile.semester} семестр</Tag>
        </Flex>
        {onClick && (
          <Button onClick={onClick} block disabled={disabled} loading={loading}>
            {disabled ? 'Выбрано' : 'Выбрать'}
          </Button>
        )}
      </Flex>
    </Card>
  );
};
