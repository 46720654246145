import { useEffect, useRef, useCallback } from 'react';

import { authWebsocket } from '@/api/endpoints/websocket';

interface WebSocketMessage {
  event: string;
  data: string;
  channel?: string;
}

type MessageHandler = (message: WebSocketMessage) => void;

export const useWebSocket = () => {
  const wsRef = useRef<WebSocket | null>(null);
  const handlersRef = useRef<Map<string, MessageHandler>>(new Map());
  const channelsRef = useRef<Set<string>>(new Set());
  const socketIdRef = useRef<string | null>(null);

  // Подключение к веб-сокету
  const connect = useCallback(() => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      return;
    }

    wsRef.current = new WebSocket(import.meta.env.VITE_WS_URL);

    wsRef.current.onmessage = (event) => {
      const message: WebSocketMessage = JSON.parse(event.data);

      if (message.event === 'pusher:connection_established') {
        const data = JSON.parse(message.data);
        socketIdRef.current = data.socket_id;
        // Подписываемся на все каналы после получения socket_id
        channelsRef.current.forEach((channel) => {
          subscribeToChannel(channel);
        });
      } else {
        const handler = handlersRef.current.get(message.event);
        if (handler) {
          handler(message);
        }
      }
    };

    wsRef.current.onopen = () => {
      console.log('WebSocket connected');
    };

    wsRef.current.onclose = () => {
      console.log('WebSocket disconnected');
      socketIdRef.current = null;
    };

    wsRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }, []);

  // Отключение от веб-сокета
  const disconnect = useCallback(() => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
  }, []);

  // Логика подписки на канал
  const subscribeToChannel = useCallback((channel: string) => {
    if (!socketIdRef.current || !wsRef.current) {
      return; // Соединение ещё не установлено
    }

    authWebsocket({
      socket_id: socketIdRef.current,
      channel_name: channel,
    })
      .then((authResponse) => {
        const subscribeMessage = {
          event: 'pusher:subscribe',
          data: {
            auth: authResponse.auth,
            channel,
          },
        };
        wsRef.current?.send(JSON.stringify(subscribeMessage));
      })
      .catch((error) => {
        console.error(`Failed to subscribe to channel ${channel}:`, error);
      });
  }, []);

  // Подписка на канал
  const subscribe = useCallback(
    (channel: string) => {
      if (!channelsRef.current.has(channel)) {
        channelsRef.current.add(channel);
        // Если соединение уже установлено, сразу подписываемся
        if (
          socketIdRef.current &&
          wsRef.current?.readyState === WebSocket.OPEN
        ) {
          subscribeToChannel(channel);
        }
        // Иначе подписка произойдёт после 'pusher:connection_established'
      }
    },
    [subscribeToChannel],
  );

  // Добавление обработчика сообщений
  const addMessageHandler = useCallback(
    (event: string, handler: MessageHandler) => {
      handlersRef.current.set(event, handler);
    },
    [],
  );

  // Удаление обработчика сообщений
  const removeMessageHandler = useCallback((event: string) => {
    handlersRef.current.delete(event);
  }, []);

  // Управление жизненным циклом веб-сокета
  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  return {
    subscribe,
    addMessageHandler,
    removeMessageHandler,
  };
};
