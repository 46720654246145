import {
  Outlet,
  useNavigate,
  useSearch,
} from '@tanstack/react-router';
// import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { Layout } from 'antd';
import { useEffect } from 'react';

import { useStyles } from './styles.ts';

export const RootPage = () => {
  const { styles } = useStyles();

  const search = useSearch({ from: '__root__' });
  const navigate = useNavigate();

  useEffect(() => {
    if ('code' in search) {
      navigate({
        // eslint-disable-next-line
        // @ts-ignore
        search: (prev) => ({ ...prev, code: undefined }),
        replace: true,
      });
    }
  }, [search, navigate]);

  return (
    <>
      <Layout className={styles.layout}>
        <Outlet />
      </Layout>
      {/* {import.meta.env.MODE === 'development' && (*/}
      {/*  <TanStackRouterDevtools position='top-left' />*/}
      {/* )}*/}
    </>
  );
};
